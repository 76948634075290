<template lang="vi">
<div>
    <address-filter :reset="reset" @reset="setting_reset" />
    <div class="row">
        <div class="col-lg-7">
            <div class="row">
                <div class="col-lg-4">
                    <b-form-group>
                        <input type="text" class="form-control" v-model="search" placeholder="Nhập tiêu đề" />
                    </b-form-group>
                </div>
                <div class="col-lg-4 margin-bottom">
                    <b-form-select v-model="category" aria-placeholder="a" class="style-poiter">
                        <b-form-select-option :value="null" disabled>Lĩnh vực</b-form-select-option>
                        <option v-for="(cat) in categories" :key="cat" :value="cat">
                            {{ cat }}
                        </option>
                    </b-form-select>
                </div>
                <div class="col-lg-4 style-poiter margin-bottom">
                    <date-picker placeholder="Ngày tạo" v-model="ngayTao" valueType="format" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY">
                    </date-picker>  
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <b-button variant="light" class="btn-refresh" @click="refresh">
                <i class="fas fa-sync"></i>
            </b-button>
        </div>
    </div>
</div>
</template>

<script>
import TableSearch from '../../../../components/datatable/table-search';
import addressFilter from '../../../../components/filter/address-filter.vue';
import {
    httpClient
} from "../../../../_utils/httpClient";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/vi';

export default {
    name: 'product-filter',
    components: {
        TableSearch,
        DatePicker,
        addressFilter,
    },
    data() {
        return {
            category: null,
            categories: [],
            ngayTao: '',
            search: "",
            select: null,
            reset: false,
        };
    },
    watch: {
        category: {
            handler: function () {
                let query = {
                    ...this.$route.query
                };
                if (this.category) {
                    query.linhVuc = this.category;
                } else {
                    delete (query.linhVuc);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                });
            },
            deep: true,
        },
        search: {
            handler: function () {
                let query = {
                    ...this.$route.query,
                };
                if (this.search == '') {
                    delete query.search
                    this.$router.push({
                        name: this.$route.name,
                        query: query,
                    });
                }
                if (this.search.length > 0) {
                    query.search = this.search;
                    setTimeout(() => {
                        if (query.search == this.search || (this.search.length < query.search.length && query.search.length == this.search.length)) {
                            this.$router.push({
                                name: this.$route.name,
                                query: query,
                            });
                        }
                    }, 800);
                } else {
                    delete query.search;
                }
            },
            deep: true,
        },
        select: {
            handler: function () {
                let query = {
                    ...this.$route.query
                };
                if (this.select) {
                    query.search = this.select;
                } else {
                    delete (query.select);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                });
            },
            deep: true,
        },
        ngayTao: {
            handler: function () {
                let query = {
                    ...this.$route.query
                };
                if (this.ngayTao) {
                    query.ngayTao = this.ngayTao;
                } else {
                    delete (query.ngayTao);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                });
            },
            deep: true,
        },
    },
    methods: {
        setting_reset() {
            this.reset = false
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'linhvucquyetdinh'
                }
            })
            this.categories = response.data.data.map(el => {
                return el.name
            })
        },
        async refresh() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.reset = true
            this.getCategories();
        }
    },
    created() {
        this.search = this.$route.query.search ? this.$route.query.search : ""
        this.category = this.$route.query.linhVuc ? this.$route.query.linhVuc : null
        this.ngayTao = this.$route.query.ngayTao ? this.$route.query.ngayTao : ""
        this.getCategories();
    },
};
</script>

<style scoped>
.style-poiter {
    cursor: pointer;
}

.style-height {
    height: 41px !important;
}

.style-width {
    width: 160px;
}

.style-margin {
    margin-left: 12px;
}

.margin-bottom {
    margin-bottom: 15px;
}
</style>
