<template>
    <div>
        <page-header :title="`Thông tin`" :items="breadcrumbs" />
        <div class="card">
            <div class="card-body">
                <div class="mb-2">
                    <product-filter @search="searching(payload)" />
                </div>
                <div v-show="!loading" class="table-responsive">
                    <table class="table table-hover table-centered table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" style="text-align:center">Tiêu đề</th>
                                <th scope="col">Lĩnh vực</th>
                                <th scope="col">Người tạo</th>
                                <th scope="col" style="width: 120px;">Ngày tạo</th>
                                <th scope="col" class="text-center style-width">Nội dung</th>
                                <th scope="col" class="style-width-col">Địa điểm</th>
                                <th scope="col">Phê duyệt</th>
                                <th scope="col" style="width: 50px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in entries" :key="entry.id">
                                <td style="max-width : 600px">
                                    {{ strippedHtml(entry.ten) }}
                                </td>
                                <td>
                                    {{ entry.linhVuc }}
                                </td>
                                <td>
                                    {{ entry.userCreateName | capitalising }}
                                </td>
                                <td>
                                    {{ entry.ngayTao | formatDate }}
                                </td>
                                <td>
                                    {{ strippedHtml(entry.noiDung) }}
                                </td>
                                <td> {{ entry.ward }}, {{ entry.district }}, {{ entry.province }}</td>
                                <td v-if="roleAdmin.includes('admin') || roleLevel == 3" style="width: 120px">
                                    <b-button v-if="entry.pheDuyet" variant="outline-success" size="sm"
                                        @click="updateStatus(entry)">Đã duyệt</b-button>
                                    <b-button v-else variant="outline-danger" size="sm" @click="updateStatus(entry)">
                                        Chưa duyệt</b-button>
                                </td>
                                <td v-else style="width : 120px">
                                    <b-button @click="updateStatus(entry)" v-if="entry.pheDuyet"
                                        variant="outline-success" size="sm" disabled>Đã duyệt</b-button>
                                    <b-button @click="updateStatus(entry)" v-else variant="outline-danger" size="sm"
                                        disabled>Chưa duyệt</b-button>
                                </td>
                                <td class="text-right" style="font-size: 16px">
                                    <router-link v-b-tooltip.hover :title="`Chi tiết`" class="text-success"
                                        :to="{ name: 'admin.informations.view', params: { id: entry.id, form: entry } }">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </router-link>
                                    <router-link
                                        v-if="roleAdmin.includes('admin') || role == entry.userCreateId || roleLevel == 3"
                                        v-b-tooltip.hover :title="`Sửa`" class="text-primary"
                                        :to="{ name: 'admin.informations.edit', params: { id: entry.id, form: entry } }">
                                        <i class="bx bx-edit"></i>
                                    </router-link>
                                    <a v-if="roleAdmin.includes('admin') || role == entry.userCreateId || roleLevel == 3"
                                        href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá`" class="text-danger"
                                        @click.prevent="deleteEntry(entry)">
                                        <i class="bx bx-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- loading -->
                <div v-show="loading" class="text-center mt-5">
                    <b-spinner variant="primary" label="Text Centered"></b-spinner>
                </div>
                <!-- table information-->
                <div style="font-size: 14px;" class="row align-items-center">
                    <div class="col-sm-8 col-xs-6 text-right">
                        <table-info :pagination="pagination" />
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <table-pagination :pagination="pagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import TableInfo from '../../../components/datatable/table-info';
import TablePagination from '../../../components/datatable/table-pagination';
import ProductFilter from './partials/product-filter';
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    httpPreview
} from "../../../_utils/httpPreview"
import {
    Base64
} from 'js-base64'

export default {
    name: 'list',
    components: {
        ProductFilter,
        TablePagination,
        TableInfo,
        PageHeader,
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Thông tin',
            to: '/admin/informations',
            active: true,
        },],
        entries: [],
        role: "",
        roleLevel: "",
        roleAdmin: "",
        pagination: {
            limit: 10,
            skip: 0,
            total: 0,
            page: 1,
            search: '',
        },
        loading: true,
    }),
    watch: {
        '$route.query': {
            handler: function () {
                this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
            },
        },
    },
    methods: {
        searching(payload) {
            this.getData(this.skip, payload);
        },
        async updateStatus(entry) {
            let pheDuyet = entry.pheDuyet
            let input = 'checkbox'
            if (pheDuyet) {
                pheDuyet = 'Hủy phê duyệt'
                input = ''
            }
            else pheDuyet = 'Duyệt'
            Swal.fire({
                title: `${pheDuyet} thông tin này?`,
                input,
                inputPlaceholder: 'Gửi thông báo đến điện thoại',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'Xác Nhận',
                confirmButtonColor: '#34c38f',
                html: `
                    <style>
                        .swal2-checkbox {
                            margin: 10px 80px 0;
                            cursor: pointer;
                        }
                        #swal2-checkbox {
                            margin-top: 2px;
                        }
                        .swal2-label {
                            font-size: 16px;
                        }
                        .swal2-html-container {
                            font-size: 20px;
                        }
                    </style>
                `
            }).then(async function (result) {
                if (result.isConfirmed) {
                    if (pheDuyet == 'Duyệt') {
                        entry.pheDuyet = true
                        if (result.value) {
                            entry.guiThongBao = true
                            await httpClient.post(`decision`, entry)
                            Swal.fire({title: `${pheDuyet} và gửi thông báo thành công !!!` });
                        } else {
                            entry.guiThongBao = false
                            await httpClient.post(`decision`, entry)
                            Swal.fire({title: `${pheDuyet} thành công !!!` });
                        }
                    }
                    else {
                        entry.pheDuyet = false
                        await httpClient.post(`decision`, entry)
                        Swal.fire({title: `${pheDuyet} thành công !!!` });
                    }
                }
                await this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
            }).catch(err => err)
        },
        firstPage() {
            let query = {
                ...this.$route.query
            };
            delete (query.page);
            this.$router.replace({
                name: this.$route.name,
                query: query,
            }).catch(err => err);
        },
        async getData(skip = 0, payload = {}) {
            this.loading = true
            if (isNaN(skip)) {
                skip = 0
            }
            this.pagination.skip = skip
            let body = {}
            if (payload.linhVuc !== undefined && payload.linhVuc !== "") {
                body = {
                    ...body,
                    "linhVuc": payload.linhVuc
                }
            }
            if (payload.ngayTao !== undefined && payload.ngayTao !== "") {
                body = {
                    ...body,
                    "ngayTao": payload.ngayTao
                }
            }
            if (payload.province) {
                body = {
                    ...body,
                    "province": payload.province
                }
            }
            if (payload.district) {
                body = {
                    ...body,
                    "district": payload.district
                }
            }
            if (payload.ward) {
                body = {
                    ...body,
                    "ward": payload.ward
                }
            }
            let response = await httpClient.post(`decision/find`, body, {
                params: {
                    ...this.pagination,
                    search: payload.search
                }
            })
            if (response.status === 200 && response.data.code === 0 && response.data.data) {
                this.entries = response.data.data
                this.pagination = {
                    ...this.pagination,
                    total: response.data.total,
                    page: Math.ceil(skip / this.pagination.limit) + 1
                }
            } else {
                this.entries = []
                this.pagination = {
                    ...this.pagination,
                    total: 0,
                    page: 1
                }
            }
            if (this.entries.length == 0) {
                this.firstPage();
                this.loading = false
                return true;
            }
            this.loading = false
        },
        replaceBrokenImage(event) {
            event.target.src = require('../../../assets/images/no-image.png');
        },
        addPrefixImage(src) {
            if (src && src.startsWith(`http`)) return src
            return httpPreview + src
        },
        strippedHtml(str) {
            let regex = /(<([^>]+)>)/ig;
            str = str.replace(regex, "")
            if (str.length > 200) {
                str = str.substr(0, 100) + '...'
            }
            return str
        },
        async deleteEntry(entry) {
            await Swal.queue([{
                title: 'Xoá Thông tin: ' + entry.ten,
                confirmButtonText: 'Xác nhận',
                cancelButtonText: 'Hủy bỏ',
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`decision/delete`, {
                        id: entry.id
                    })
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Xoá thành công',
                        });
                        await this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
                    } else {
                        Swal.insertQueueStep({
                            title: 'Xoá không thành công',
                        });
                    }
                    return true;
                },
            },]);
        },
        getUserData() {
            let userData = localStorage.getItem('token').split('.')[1];
            let userInfo = JSON.parse(Base64.decode(userData));
            this.role = userInfo.phone;
            this.roleAdmin = userInfo.role;
            this.roleLevel = userInfo.role_level
        }
    },
    created() {
        this.getUserData();
        this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
    },
};
</script>

<style scoped>
.style-width {
    min-width: 150px;
}

.style-width-col {
    min-width: 120px;
}
</style>
